export enum RewardType {
  DIRECT = 'direct',
  EXTENDED = 'extended',
}

export enum RewardReportTransactionStatus {
  NEW = 'new',
  PAID = 'paid',
  WITHDRAWN = 'withdrawn',
}
