import { Button, Card, Col, Row, message } from 'antd';
import ClassCard, { CardChangedValue } from './ClassCard';
import {
  // ClassData,
  Classes,
  // useGetStudentSelectedClassesLazyQuery,
} from '@services/data-access';
import React, {
  Fragment,
  FunctionComponent,
  useEffect,
  useRef,
  useState,
} from 'react';

import { formatCurrencyFullText } from '@helpers';
import { CreatePaymentAdviceFormSession } from '../interface/CreatePaymentAdviceFormSession';
import { DialogResult } from '@models/dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Icon from '@ant-design/icons/lib/components/Icon';
import SelectClassPopUp from './select-class-popup/SelectClassPopUp';
import SessionHeader from '../components/SessionHeader';
import { usePrevious } from '@hooks';
import isEqual from 'lodash/isEqual';
import moment from 'moment';

export interface ClassInfoProps extends CreatePaymentAdviceFormSession {
  onUpdateData: () => void;
}

const ClassInfo: FunctionComponent<ClassInfoProps> = ({
  onUpdateData,
  onChange,
  disabled,
  paymentAdvice,
}) => {
  const selectedStudent = useRef('');
  const prevPaymentAdvice = usePrevious(paymentAdvice);
  const [showSelectClassPopup, setShowSelectClassPopup] = useState(false);

  const [selectedClass, setSelectedClass] = useState<ClassData[]>([]);

  // const [
  //   getSelectedClassesAfterCreateStudent,
  //   {
  //     called: getSelectedClassesAfterCreateStudentCalled,
  //     fetchMore: refreshSelectedClassesAfterCreateStudent,
  //   },
  // ] = useGetStudentSelectedClassesLazyQuery({
  //   notifyOnNetworkStatusChange: true,
  //   onError: () => {},
  //   onCompleted: result => {
  //     // Only update in case empty Class
  //     if (
  //       selectedClass.length <= 0 &&
  //       paymentAdvice.student?.studentProfile?.willPayLater
  //     ) {
  //       result.class_students_waiting.forEach(item => {
  //         if (
  //           !selectedClass.find(
  //             findSelectedClass => findSelectedClass.id === item.id,
  //           )
  //         ) {
  //           selectedClass.push({
  //             ...item,
  //             id: item.class.id,
  //             name: item.class.name,
  //           } as any);
  //         }
  //       });
  //       setSelectedClass(selectedClass.slice());
  //     }
  //   },
  // });

  useEffect(() => {
    if (!isEqual(prevPaymentAdvice, paymentAdvice)) {
      if (paymentAdvice.classInformations) {
        if (
          selectedStudent.current !== '' &&
          selectedStudent.current !== paymentAdvice.student?.id
        ) {
          selectedClass.splice(0, selectedClass.length);
        }
        selectedStudent.current = paymentAdvice.student?.id || '';

        // eslint-disable-next-line no-unused-expressions
        paymentAdvice.classInformations?.forEach(item => {
          const index = selectedClass.findIndex(
            classItem => classItem.id === item?.id,
          );
          if (index >= 0) {
            selectedClass[index] = {
              ...selectedClass[index],
              ...item,
            } as any;
          } else {
            selectedClass.push({
              ...selectedClass[index],
              ...item,
            } as any);
          }
        });
        setSelectedClass(selectedClass.slice());
      }
      if (paymentAdvice.classInformations?.length === 0) {
        // if (paymentAdvice.student?.id) {
        //   const filter = {
        //     class: { classStatus: { _neq: 'closed' } },
        //     studentId: {
        //       _eq: paymentAdvice.student?.id,
        //     },
        //   };
        //   if (getSelectedClassesAfterCreateStudentCalled) {
        //     refreshSelectedClassesAfterCreateStudent({
        //       variables: { filter },
        //       updateQuery: (prev, { fetchMoreResult }) => {
        //         if (!fetchMoreResult) return prev;
        //         return fetchMoreResult;
        //       },
        //     });
        //   } else {
        //     getSelectedClassesAfterCreateStudent({
        //       variables: { filter },
        //     });
        //   }
        // }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentAdvice.classInformations, paymentAdvice.student]);

  const addClassClick = () => {
    if (!paymentAdvice.companyId) {
      message.warning('Please select company!');
    } else if (!paymentAdvice.student) {
      message.warning('Please select student!');
    } else {
      setShowSelectClassPopup(true);
    }
  };

  const onCloseAddClassPopUp = (value: DialogResult, payload?: Classes[]) => {
    setShowSelectClassPopup(false);
    if (payload) {
      setSelectedClass(
        payload.map(item => {
          return {
            ...item,
            id: item.id,
            programId: item.programId,
            name: item.name,
          } as any;
        }),
      );

      const specialClasses = payload.reduce((_classes: any, _class: any) => {
        if (_class?.program?.cate?.code === 'special') {
          _classes.push({
            id: _class.id,
            lessonStartDate: moment(_class?.lessons?.[0]?.date).format(
              'YYYY-MM-DD',
            ) as string,
            quantityAdditionalLesson: 0,
            termIds: [] as string[],
            discountIds: [] as string[],
            additionalDiscount: 0,
          });
        }
        return _classes;
      }, []);

      if (specialClasses?.length > 0) {
        setTimeout(() => {
          onChange({
            classInformations: specialClasses as any,
            studentId: paymentAdvice.student?.id,
          });
        }, 1000);
      }
    }
  };

  const onDelete = (id: string) => {
    const index = selectedClass.findIndex(item => item.id === id);
    if (index >= 0) {
      selectedClass.splice(index, 1);
      setSelectedClass(selectedClass.slice());
      if (paymentAdvice?.classInformations?.length) {
        const index = paymentAdvice.classInformations.findIndex(item => {
          if (item) {
            return item.id === id;
          }
          return false;
        });
        if (index >= 0) {
          paymentAdvice.classInformations.splice(index, 1);
        }
      }
      updatePA();
    }
  };

  const updatePA = (value?: CardChangedValue) => {
    const tempArray: CardChangedValue[] =
      paymentAdvice.classInformations?.map(item => {
        return {
          ...item,
          id: item?.id,
          lessonStartDate: item?.lessonStartDate,
          quantityAdditionalLesson:
            item?.responses?.additionalLesson?.quantity ||
            item?.quantityAdditionalLesson ||
            0,
          termIds: item?.terms?.map(term => term?.id),
          discountIds: item?.discounts?.map(discount => discount?.id),
          additionalDiscount: item?.additionalDiscount
            ? Number(item?.additionalDiscount)
            : 0,
        } as CardChangedValue;
      }) || [];

    if (value) {
      const index = tempArray?.findIndex(item => item?.id === value.id);
      if (index >= 0 && !!value.termIds.length) {
        tempArray[index] = {
          ...tempArray[index],
          ...value,
        };
      } else if (index >= 0 && !value.termIds.length) {
        tempArray.splice(index, 1);
      } else if (index < 0) {
        tempArray.push(value);
      }
    }

    // onUpdateData();
    onChange({
      classInformations: tempArray as any,
      studentId: paymentAdvice.student?.id,
    });
  };

  return (
    <div className="create-pa__group">
      <SessionHeader
        title="Class Fee"
        subTitle={`(Total: ${
          (paymentAdvice.paymentSummaryStatic?.classFee ?? 0) < 0
            ? formatCurrencyFullText(
                paymentAdvice.paymentSummaryStatic?.classFee?.toString() || '0',
                true,
              )
            : formatCurrencyFullText(
                paymentAdvice.paymentSummaryStatic?.classFee?.toString() || '0',
              )
        })`}
      />

      <Row gutter={[20, 20]}>
        <Col span={24}>
          {!disabled && (
            <Button
              onClick={addClassClick}
              block
              className="text-info"
              icon={
                <Icon viewBox={'0, 0, 1024, 1024'}>
                  <FontAwesomeIcon icon={['fal', 'plus']} />
                </Icon>
              }
            >
              Add Class
            </Button>
          )}
        </Col>
      </Row>

      <Row gutter={[20, 20]}>
        {selectedClass.length > 0 ? (
          <Fragment>
            {selectedClass.map(item => {
              const _class = paymentAdvice?.classInformations?.find(
                ({ id }) => item.id === id,
              );
              return (
                <Col key={item.id || ''} span={24}>
                  <ClassCard
                    key={`${item.id || ''}-{${
                      paymentAdvice.student?.id || ''
                    }}`}
                    studentId={paymentAdvice.student?.id || ''}
                    issueDate={paymentAdvice.issueDate || ''}
                    classSelect={{ ...item, ..._class }}
                    companyId={paymentAdvice.companyId}
                    paymentAdvice={paymentAdvice}
                    onDelete={onDelete}
                    onChange={updatePA}
                    disable={disabled}
                  />
                </Col>
              );
            })}
          </Fragment>
        ) : (
          <Col span={24}>
            <Card
              className="text-center text-secondary"
              style={{ background: '#fff' }}
            >
              <div>
                Click “Add Class" to start creating the Payment Advice.
                <br />
                You have to add 1 Regular class before adding any Double Lesson
                class.
              </div>
            </Card>
          </Col>
        )}
      </Row>

      <SelectClassPopUp
        previousSelectedClasses={selectedClass.map(item => {
          return {
            ...item,
            id: item.id,
          } as Classes;
        })}
        companyId={paymentAdvice?.companyId || ''}
        visible={showSelectClassPopup}
        onClose={onCloseAddClassPopUp}
      />
    </div>
  );
};

export default ClassInfo;
