import { Button, Col, DatePicker, Form, Modal, Row, Select } from 'antd';
import Pill, { PillType } from '@components/Pill/Pill';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useGetAllCompaniesQuery } from '@services/data-access';
import { CreatePaymentAdviceFormSession } from '../interface/CreatePaymentAdviceFormSession';
import CreatePaymentAdviceSession from '../components/CreatePaymentAdviceSession';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HistoryPALogsTable from '../../../ModalHistoryLogsTable/PALogs';
import { Moment } from 'moment';
import { PaymentAdviceStatus } from '@models/payment-advice';
import moment from 'moment-timezone';
import { useGetBranchesQuery } from '@services/data-access';

export interface PaymentInfoProps extends CreatePaymentAdviceFormSession {
  disabled?: boolean;
  isLogDetail?: boolean;
}

export interface FormValue {
  issueDate: Moment;
  dueDate: Moment;
  branchId: string;
  qrExpiryDate: Moment;
  companyId: string;
}
const PaymentInfo: FunctionComponent<PaymentInfoProps> = ({
  onChange,
  paymentAdvice,
  disabled,
  isLogDetail,
}) => {
  const [pillColor, setPillColor] = useState<PillType>('info');
  const [paymentStatus, setPaymentStatus] = useState('');
  const [showHistory, setShowHistory] = useState(false);

  const { data: branchesList } = useGetBranchesQuery();
  const [form] = Form.useForm();
  const onFormChanged = (value: any) => {
    const mappedValue = value as FormValue;
    if (mappedValue.companyId) {
      onChange({
        companyId: mappedValue.companyId,
      });
    }
    if (mappedValue.branchId) {
      onChange({
        branchId: mappedValue.branchId,
      });
    }
    if (mappedValue.dueDate) {
      onChange({
        dueDate: moment(mappedValue.dueDate).utc().startOf('day').toISOString(),
      });
    }
    if (mappedValue.issueDate) {
      onChange({
        issueDate: moment(mappedValue.issueDate)
          .utc()
          .startOf('day')
          .toISOString(),
      });
    }

    if (mappedValue.qrExpiryDate) {
      onChange({
        hasExpiryDate: true,
        qrExpiryDate: moment(mappedValue.qrExpiryDate)
          .utc()
          .startOf('day')
          .toISOString(),
      });
    }
  };
  const {
    data: companyData,
    loading: loadingCompanies,
    error: errorLoadingCompanies,
  } = useGetAllCompaniesQuery({ onError: () => {} });

  useEffect(() => {
    switch (paymentAdvice.paymentStatus as PaymentAdviceStatus) {
      case PaymentAdviceStatus.Draft:
        setPillColor('draff');
        break;
      case PaymentAdviceStatus.OverDue:
        setPillColor('brown');
        break;
      case PaymentAdviceStatus.Paid:
        setPillColor('success');
        break;
      case PaymentAdviceStatus.UnPaid:
        setPillColor('danger');
        break;
      case PaymentAdviceStatus.Voided:
        setPillColor('brown');
        break;
      case PaymentAdviceStatus.Withdrawn:
        setPillColor('primary');
        break;

      default:
        break;
    }

    let paymentStatus = '';

    if (
      moment(new Date()).isSameOrAfter(
        moment(paymentAdvice.dueDate).format('YYYY-MM-DD 23:59:59'),
      ) &&
      paymentAdvice.paymentStatus === PaymentAdviceStatus.UnPaid
    ) {
      setPillColor('brown');
      paymentStatus = 'Overdue';
    }
    setPaymentStatus(
      paymentStatus ? paymentStatus : paymentAdvice.paymentStatus || '',
    );
    form.setFieldsValue({
      companyId: paymentAdvice.companyId,
      branchId: paymentAdvice.branch?.id,
      // branchId: paymentAdvice.branchId,
      issueDate: paymentAdvice.issueDate
        ? moment(paymentAdvice.issueDate)
        : undefined,
      dueDate: paymentAdvice.dueDate
        ? moment(paymentAdvice.dueDate)
        : undefined,
      qrExpiryDate: paymentAdvice.qrExpiryDate
        ? moment(paymentAdvice.qrExpiryDate)
        : undefined,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentAdvice]);

  const disableIssuedDate = (current: any) => {
    const dueDate = form.getFieldValue('dueDate');
    if (dueDate) {
      return current && current > moment(dueDate).startOf('day');
    } else {
      return false;
    }
  };

  const disableDueDate = (current: any) => {
    const issueDate = form.getFieldValue('issueDate');

    if (issueDate) {
      return current && current < moment(issueDate).endOf('day');
    } else {
      return false;
    }
  };

  const disableExpiryDate = (current: any) => {
    return current && current < moment().endOf('day');
  };

  return (
    <div>
      <CreatePaymentAdviceSession
        title="Payment Information"
        displaySelection={false}
        subTitle={
          !isLogDetail && (
            <>
              <div className="text-secondary text-small"></div>
              {!disabled && (
                <Button
                  style={{ padding: 0 }}
                  type="link"
                  icon={<FontAwesomeIcon icon={['fal', 'history']} size="lg" />}
                  onClick={() => {
                    setShowHistory(true);
                  }}
                >
                  <span style={{ marginLeft: 10 }}>Log history</span>
                </Button>
              )}
            </>
          )
        }
      >
        <Form form={form} layout="vertical" onValuesChange={onFormChanged}>
          <Row gutter={[20, 10]}>
            <Col span={8}>
              <div className="text-secondary text-small">
                Payment Advice Ref.
              </div>
              <div className="text-bold text-ink">
                {paymentAdvice.name || ''}
              </div>
            </Col>
            <Col span={8}>
              <div className="text-secondary text-small">Status</div>
              <Pill text={paymentStatus} type={pillColor}></Pill>
            </Col>
            <Col span={8}>
              {paymentAdvice.axsCode && (
                <>
                  <div className="text-secondary text-small">AXS Code</div>
                  <div className="text-bold text-ink">
                    {paymentAdvice.axsCode}
                  </div>
                </>
              )}
            </Col>
            {/* <Col span={16}>
              <Row justify="space-between" align="bottom">
                <Col span={8}>
                  <div className="text-secondary text-small">Status</div>
                  <Pill text={paymentStatus} type={pillColor}></Pill>
                </Col>
                {paymentAdvice.axsCode && (
                  <Col>
                    <div className="text-secondary text-small">AXS Code</div>
                    <div className="text-bold text-ink">
                      {paymentAdvice.axsCode}
                    </div>
                  </Col>
                )}
                {!isLogDetail && (
                  <Col>
                    <div className="text-secondary text-small"></div>
                    {!disabled && (
                      <Button
                        style={{ padding: 0 }}
                        type="link"
                        icon={
                          <FontAwesomeIcon
                            icon={['fal', 'history']}
                            size="lg"
                          />
                        }
                        onClick={() => {
                          setShowHistory(true);
                        }}
                      >
                        <span style={{ marginLeft: 10 }}>Log history</span>
                      </Button>
                    )}
                  </Col>
                )}
              </Row>
            </Col> */}
          </Row>

          <Row gutter={[20, 0]}>
            <Col span={8}>
              <Form.Item
                label="(*) Company"
                rules={[{ required: true, message: 'Please select company!' }]}
                name="companyId"
              >
                <Select placeholder="Select Company" style={{ width: '100%' }}>
                  {companyData?.companies?.map((cp: any) => (
                    <Select.Option key={cp.id} value={cp.id}>
                      {cp.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="(*) Issue Date"
                rules={[
                  { required: true, message: 'Please select issue date!' },
                ]}
                name="issueDate"
              >
                <DatePicker
                  disabledDate={disableIssuedDate}
                  format="DD/MM/YYYY"
                  disabled={disabled}
                  placeholder="Select Date"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="(*) Due Date"
                rules={[
                  { required: true, message: 'Please select issue date!' },
                ]}
                name="dueDate"
              >
                <DatePicker
                  disabledDate={disableDueDate}
                  disabled={disabled}
                  format="DD/MM/YYYY"
                  placeholder="Select Date"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="(*) Issued Branch"
                rules={[{ required: true, message: 'Please select branch!' }]}
                name="branchId"
              >
                <Select placeholder="Select Branch" disabled={disabled}>
                  {branchesList?.branches.map(item => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="(*) QR Expiry Date"
                rules={[{ required: true, message: 'Please QR Expiry Date!' }]}
                name="qrExpiryDate"
              >
                <DatePicker
                  disabledDate={disableExpiryDate}
                  format="DD/MM/YYYY"
                  disabled={disabled}
                  style={{ width: '100%' }}
                  placeholder="Select Expiry Date"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </CreatePaymentAdviceSession>
      <Modal
        visible={showHistory}
        onCancel={() => {
          setShowHistory(false);
        }}
        footer={null}
        title="Log History"
        width={1100}
      >
        <HistoryPALogsTable id={paymentAdvice?.id ? paymentAdvice?.id : ''} />
      </Modal>
    </div>
  );
};

export default PaymentInfo;
