import React, { Fragment, FunctionComponent, ReactNode } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import Header from '@components/Header/Header';
import CompanyRoute from '@pages/company/router';
import UserRoute from '@pages/user/router';
import BranchRoute from '@pages/branch/router';
import Communication from '@pages/communication/router';
import ProgrammeRoute from '@pages/programme/router';
import StudentManagementRoute from '@pages/student/router';
import AttendanceRoute from '@pages/attendance/router';
import ClassRoute from '@pages/class/router';
import FinanceRoute from '@pages/finance/router';
import ReportRoute from '@pages/report/router';
import RewardRoute from '@pages/reward/router';
// import ContentManagementRoute from '@pages/content-management/router';
// import EnquiryRoute from '@pages/enquiry/router';

// import GraduationRoute from '@pages/graduation/router';

export interface IRoute {
  path: string;
  exact?: boolean;
  component: ReactNode;
  title: string;
  isPublic?: boolean;
  roles?: any[];
}

export interface PrivateRouteProps {
  path: string;
  title: string;
  isAuthenticated: boolean;
  children: ReactNode;
  collapsed: boolean;
  to: string;
}

// @ts-ignore
export const authenticatedRouter: IRoute[] = [
  ...CompanyRoute,
  ...UserRoute,
  ...BranchRoute,
  ...ProgrammeRoute,
  ...Communication,
  ...ClassRoute,
  ...StudentManagementRoute,
  ...FinanceRoute,
  ...ReportRoute,
  ...AttendanceRoute,
  ...RewardRoute,
  // ...ContentManagementRoute,
  // ...EnquiryRoute,
  // ...GraduationRoute,
];

const PrivateRoute: FunctionComponent<PrivateRouteProps> = ({
  path,
  children,
  isAuthenticated,
  ...props
}) => {
  const { pathname } = useLocation();

  // if (isAuthenticated && (pathname === '/' || pathname === '/login')) {
  //   return <Redirect to={to} />;
  // }

  return (
    <Route
      exact
      {...props}
      render={({ location, match }) => {
        const crumbs = authenticatedRouter
          .filter(({ path }) => match.path.includes(path))
          .map(({ path, ...rest }) => ({
            path: Object.keys(match.params).length
              ? Object.keys(match.params).reduce(
                  (path, param) =>
                    path.replace(`:${param}`, match.params[param] as string),
                  path,
                )
              : path,
            ...rest,
          }));

        return isAuthenticated ? (
          <Fragment>
            <Header
              title={props.title}
              breadcrumbs={crumbs}
              isCollapse={props.collapsed}
              className={path.replaceAll('/', '')}
            />
            {children}
          </Fragment>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
