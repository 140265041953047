import {
  IDailyClosingReq,
  IDailyReceiptReq,
  IRevenueRegconitionReq,
} from '@interfaces/network';
import { IEnrollmentListByUserIds } from '@interfaces/network/req/IEnrollmentListByUserIdsReq';
import { apiService } from '@services/api';

const getDailyClosingReport = async (request: IDailyClosingReq) =>
  await apiService.get(`/reports/daily-closing`, {
    params: request,
  });

const getDailyReceiptReport = async (request: IDailyReceiptReq) =>
  await apiService.get(`/reports/daily-receipt`, {
    params: request,
  });

const getRevenueRegconitionReport = async (request: IRevenueRegconitionReq) =>
  await apiService.get(`/reports/revenue`, {
    params: request,
  });

const getDepositReport = async () =>
  await apiService.get(`/reports/deposit-report`);

const enrollmentListByUserIds = async (request: IEnrollmentListByUserIds) =>
  await apiService.post(`/reports/enrollment-list-by-user-ids`, request);

export {
  getDailyClosingReport,
  getDailyReceiptReport,
  getRevenueRegconitionReport,
  getDepositReport,
  enrollmentListByUserIds,
};
