import { AutoComplete, Avatar, Col, Empty, Input, Row } from 'antd';
import {
  // FormPaymentAdvice,
  // StudentModel,
  Users,
  useFilterUserWithPaginationQuery,
  useGetStudentDetailLazyQuery,
} from '@services/data-access';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { StatusCode, UserRole } from '@models';

import CreatePaymentAdviceSession from '../components/CreatePaymentAdviceSession';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Icon from '@ant-design/icons/lib/components/Icon';
import StudentCardCreatePaymentAdvice from '@components/StudentCardCreatePaymentAdvice/StudentCardCreatePaymentAdvice';

export interface StudentInfoProps {
  // onChange: (value: FormPaymentAdvice) => void;
  onChange: (value: any) => void;
  disabled?: boolean;
  // student: Maybe<StudentModel>;
  student: any;
}

const StudentInfo: FunctionComponent<StudentInfoProps> = ({
  student,
  disabled,
  onChange,
}) => {
  const [searchReferralString, setSearchReferralString] = useState('');
  const [selectedReferralDetail, setSelectedReferralDetail] = useState<Users[]>(
    [],
  );

  const [getStudent, { fetchMore: getStudentDetail, called }] =
    useGetStudentDetailLazyQuery({
      onCompleted: result => {
        if (result) {
          setSelectedReferralDetail([result.users_by_pk as Users]);
        }
      },
    });

  useEffect(() => {
    if (student?.id) {
      if (called) {
        getStudentDetail({
          variables: {
            id: student?.id || '',
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return fetchMoreResult;
          },
        });
      } else {
        getStudent({
          variables: {
            id: student?.id,
          },
        });
      }
    }
    // eslint-disable-next-line
  }, [student?.id]);

  const { data: studentsReferral, fetchMore: fetchMoreStudentsReferral } =
    useFilterUserWithPaginationQuery({
      variables: {
        offset: 0,
        limit: 20,
        filter: {
          status: {
            _neq: StatusCode.Deleted,
          },
          role: {
            _eq: UserRole.Student,
          },
        },
      },
      onError: () => {},
    });

  useEffect(() => {
    if (
      // selectedReferralDetail &&
      // selectedReferralDetail.length > 0 &&
      // selectedReferralDetail[0] !== null
      searchReferralString &&
      searchReferralString.trim() !== ''
    ) {
      fetchMoreStudentsReferral({
        variables: {
          offset: 0,
          limit: 20,
          filter: {
            _and: [
              {
                status: {
                  _neq: StatusCode.Deleted,
                },
              },
              {
                status: {
                  _neq: 'draft',
                },
              },
            ],
            role: {
              _eq: UserRole.Student,
            },
            _or: [
              {
                username: {
                  _ilike: `%${searchReferralString}%`,
                },
              },
              {
                fullName: {
                  _ilike: `%${searchReferralString}%`,
                },
              },
            ],
            id: {
              _nin: selectedReferralDetail.map(e => e.id),
            },
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return fetchMoreResult;
        },
      });
    }
    // eslint-disable-next-line
  }, [searchReferralString]);

  const renderStudentItem = (scope: Users) => {
    return {
      value: scope.id,
      label: (
        <div className="relative student-option-item">
          <Avatar
            size={28}
            style={{
              position: 'absolute',
              top: 'calc(50% - 14px)',
              left: 0,
            }}
            src={scope.avatarUrl || undefined}
            icon={
              <Icon viewBox={'0, 0, 1024, 1024'}>
                <FontAwesomeIcon icon={['far', 'user']} />
              </Icon>
            }
          />
          <p>{`${scope.firstName} ${scope.lastName} - ${scope.username} - ${
            scope.studentProfile?.school?.name || '__'
          } - ${scope.studentProfile?.curriculum?.abbreviation || '__'} - ${
            scope.studentProfile?.level?.abbreviation || '__'
          }`}</p>
        </div>
      ),
    };
  };

  const selectReferral = (value: string) => {
    const _student = studentsReferral?.users.find(e => e.id === value);
    if (_student) {
      setSearchReferralString('');
      setSelectedReferralDetail([_student as Users]);
      onChange({ studentId: _student.id });
    }
  };

  return (
    <div>
      <CreatePaymentAdviceSession title="Student Information">
        <AutoComplete
          dropdownClassName="certain-category-search-dropdown"
          dropdownMatchSelectWidth={true}
          style={{ width: '100%', marginBottom: 20 }}
          value={searchReferralString}
          onSearch={inputValue => setSearchReferralString(inputValue)}
          onSelect={value => selectReferral(value)}
          options={studentsReferral?.users.map(student =>
            renderStudentItem(student as any),
          )}
          disabled={disabled}
          notFoundContent={<Empty />}
        >
          <Input
            prefix={
              <Icon viewBox={'0, 0, 1024, 1024'}>
                <FontAwesomeIcon icon={['fal', 'search']} />
              </Icon>
            }
            disabled={disabled}
            size="large"
            placeholder="Search by student name or username"
          />
        </AutoComplete>
        <Row gutter={[20, 20]}>
          <Col>
            {selectedReferralDetail.length > 0 ? (
              <StudentCardCreatePaymentAdvice
                student={selectedReferralDetail[0]}
              ></StudentCardCreatePaymentAdvice>
            ) : null}
          </Col>
        </Row>
      </CreatePaymentAdviceSession>
    </div>
  );
};

export default StudentInfo;
// export default memo(StudentInfo, (prev, next) => {
//   return (
//     !Boolean(next.student?.id) ||
//     prev.student?.id === next.student?.id ||
//     prev.disabled === next.disabled
//   );
// });
